import axios from 'axios'
import { Message } from "element-ui"
import store from '@/store'
import router from '@/router'

//请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.method === "get") {
      config.data = { unused: 0 }; // 这个是关键点，加入这行就可以了,解决get,请求添加不上Content-Type
    }
    //除了这几个登录注册和验证码的接口都需要带上token
    const url = config.url
    if (url.indexOf('/api/Login') == -1 && url.indexOf('/api/Register') == -1
     && url.indexOf('/api/VerifyCode') == -1
     && url.indexOf('/ws/geocoder/v1') == -1) {
      //请求头带上token
      config.headers.Authorization = 'Bearer ' + store.getters.getToken
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//响应拦截器
axios.interceptors.response.use(
  response => {
    try {
      if (response.data.code == 0 && response.data.msg) {
        Message({
          type: 'success',
          message: response.data.msg,
          duration: 8000
        })
      }
      if (response.data.code != 0) {
        Message({
          type: 'error',
          message: response.data.msg,
          duration: 8000
        })
      }
      return response.data
    } catch (error) {
      return response.data
    }
  },
  error => {
    //未携带或者token错误都是401
    if (String(error).indexOf('401') != -1 || !store.getters.getToken) {
      Message({
        type: 'error',
        message: '身份已过期，请重新登录',
        duration: 8000
      })
      router.push('/login')
    }
    return Promise.resolve(error.response)
  }
)

export default axios